//user account actions
function UsersFactory ($resource, $http, SETTINGS) {
  const baseUrl = `${SETTINGS.apiEndpoint}user`;

  var User = $resource(baseUrl + '/:id',{id: 'me'},
  {
    login: {
      method: 'POST',
      params: {
        id: 'local',
        email: '@email',
        password: '@password',
      }
    },
    sendResetLink: {
      method: 'POST',
      params: {
        id: 'reset/',
        email: '@email',
      },
    },
    resetPassword: {
      method: 'POST',
      params: {
        id:'@id',
        password: '@password',
      }
    },
    changePassword: {
      method: 'PUT',
      params: {
        controller:'password',
      }
    },
    register: {
      method: 'PUT',
      params: {
        id: ''
      }
    },
    update: {
      method: 'POST',
    },
    getAllUsers: {
      method: 'GET',
      params: {
        id: 'public',
      },
      isArray: true,
    }
  });

  User.prototype.patch = function(data) {
    return $http.post(`${baseUrl}/me`, data).then((response) => {
      return response.data;
    });
  };

  return User;
}

angular.module('sq.user.model',['ngResource','sq.config'])
.factory('User', UsersFactory);